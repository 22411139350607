<template>
  <div class="group">
    <div class="df-row">
      <div class="df-col">
        <el-select v-model="submitData.onsale" size="mini" placeholder="请选择">
          <el-option
            v-for="item in onSaleSelector"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-input
          v-model="submitData.word"
          size="mini"
          placeholder="请输入内容"
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAddGroup('')"
          >新增团购商品</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="tableList"
      :max-height="tableMaxHeight"
      border
      stripe
    >
      <el-table-column prop="ItemName" label="商品名称"> </el-table-column>
      <el-table-column prop="GrouponHours" label="成团时效(小时)">
      </el-table-column>
      <el-table-column prop="" label="开团时间">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.SaleBeginTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="结束时间">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.SaleEndTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="商品状态">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.OnSale ? 'success' : 'warning'"
            size="default"
            >{{ scope.row.OnSale ? "上架" : "下架" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onAddGroup(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            size="mini"
            :disabled="delDisabled"
            @click="deleteGrouponSetting(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <AddGroupDialog
      ref="AddGroupDialog"
      @success="getGrouponSettingMaintList"
    ></AddGroupDialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

import AddGroupDialog from "./components/add-group-dialog.vue";

export default {
  components: {
    AddGroupDialog,
  },

  data() {
    return {
      totalRecord: 0,
      tableMaxHeight: "",
      onSaleSelector: [],
      submitData: {
        onsale: -1,
        word: "",
        page_index: 1,
        page_size: 20,
      },
      tableList: [],
      loading: false,
      delDisabled: false
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initItemMaintList();
    await this.getGrouponSettingMaintList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 40);
    },

    // 初始化商品列表
    async initItemMaintList() {
      try {
        let { data } = await Shop.initItemMaintList();
        this.onSaleSelector = data.onSaleSelector;
      } catch (err) {
        console.log(err);
      }
    },

    // 查询拼团设置列表
    async getGrouponSettingMaintList() {
      this.loading = true;
      try {
        let { data } = await Shop.getGrouponSettingMaintList(this.submitData);
        this.tableList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 删除拼团商品
    deleteGrouponSetting(event) {
      this.$confirm("是否确定删除该拼团商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.delDisabled = true
        try {
          let { errcode } = await Shop.deleteGrouponSetting({
            groupon_setting_id: event.GrouponSettingId,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getGrouponSettingMaintList();
          }
        } catch (err) {
          console.log(err);
        } finally{
          this.delDisabled = false
        }
      });
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getGrouponSettingMaintList();
    },

    // 打开添加、编辑团购商品对话框
    onAddGroup(event) {
      this.$refs.AddGroupDialog.onShowDialog(event);
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getGrouponSettingMaintList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getGrouponSettingMaintList();
    },
  },
};
</script>

<style lang="less" scoped>
.group {
  .df-row {
    margin-top: 20px;
  }

  .el-pagination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
